function validateDateOfBirth(value) {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
	const match = value.match(regex);

	let response = { isValid: false, message: null}

	if (!match) {
		response.isValid = false;
		response.message = "Formato inválido. Use dd/mm/yyyy.";

		return response;
	}

	const day = parseInt(match[1], 10);
	const month = parseInt(match[2], 10);
	const year = parseInt(match[3], 10);

	if (day < 1 || day > 31) {
		response.isValid = false;
		response.message = "Dia inválido. Deve estar entre 1 e 31.";

		return response;
	}

	if (month < 1 || month > 12) {
		response.isValid = false;
		response.message = "Mês inválido. Deve estar entre 1 e 12.";

		return response;
	}

	if (year > currentYear || currentYear - year > 200) {
		response.isValid = false;
		response.message = `Ano inválido. Deve estar entre ${currentYear - 200} e ${currentYear}.`;

		return response;
	}

	const dataValida = new Date(year, month - 1, day);
	if (
		dataValida.getFullYear() !== year ||
		dataValida.getMonth() + 1 !== month ||
		dataValida.getDate() !== day
	) {
		response.isValid = false;
		response.message = "Data inválida.";

		return response;
	}

	response.isValid = true;
	return response;
}

function maskDateOfBirth(value) {
	value = value.replace(/\D/g, "");

	value = value.slice(0, 8);

	if (value.length <= 2) {
		value = value.replace(/(\d{2})/, "$1");
	} else if (value.length <= 4) {
		value = value.replace(/(\d{2})(\d{1,2})/, "$1/$2");
	} else {
		value = value.replace(/(\d{2})(\d{2})(\d{1,4})/, "$1/$2/$3");
	}

	return value;
}

function convertDateOfBirthToIsoFormat(dataPtBr) {
	if (!/^\d{2}\/\d{2}\/\d{4}$/.test(dataPtBr)) {
		return null
	}

	const [dia, mes, ano] = dataPtBr.split("/");

	return `${ano}-${mes}-${dia}`;
}

export {
	validateDateOfBirth,
	maskDateOfBirth,
	convertDateOfBirthToIsoFormat
}
