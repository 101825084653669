import React, { Component, Fragment } from 'react'
import currency from 'currency.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faAngleDown, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faClock, faTimesCircle, } from '@fortawesome/free-regular-svg-icons'
import { format } from 'date-fns'
import ReactPixel from 'react-facebook-pixel'
import { Footer, NavMenu } from '../../components'
import { getCardType, getImgLogoCard, returnNameCard } from '../../utils/paymentCardUtil'
import { toCpfOrCnpj, maskCpfCnpj, validateCpfCpnj } from '../../utils/cpfCnpjFormatter'
import { getPaymentData, settleQuotation, getCheckoutSetup, settleAutomaticValidation } from '../../netoworking/CheckoutNetwork'
import { tdsAuthenticate, getTdsResult } from '../../netoworking/TdsNetwork'
import { SUCCESS_RESULT, LINK_EXPIRED_RESULT, ERROR_RESULT, NOT_REGISTERED_RESULT, PAYMENT_IN_ANALYSIS, PAYMENT_WAITING_PIN_VALIDATION, PAYMENT_DECLINED, ERROR_LINK, ERROR_CONFIG_CNPJ, PAYMENT_APPROVED, PIN_VALIDATED } from '../../models/paymentResultType'
import { DEFAULT_MESSAGE, CARD_NOT_APPROVED, TRY_ANOTHER_CARD } from '../../models/paymentErroMenssage'
import { PAID, UNPAYABLE, UNPAID } from '../../models/bankSlipStatusCode';
import { BANKS_LIST } from '../../models/banksList';
import { EXPIRED_STATUS, PAID_STATUS, OPEN_STATUS, MISSING_REGISTRY_BANKSLIP_CNPJ_STATUS } from '../../models/paymentLinkStatusCode'
import term from '../../assets/documents/termo.pdf'
import PaymentResult from './paymentResult/index.jsx'
import './index.css'
import NumberFormat from 'react-number-format';
import { maskPhone, unformatValue } from '../../utils/masks'
import { DEFAULT_ERRO, 
         TRANSACTION_NOT_ALLOWED, 
         CONTACT_CARD_CENTER, 
         INVALID_INSTALLMENT, 
         NOT_ALLOWED_CARD, 
         CARD_ERROR, 
         INVALID_ESTABLISHMENT,
         TRANSACTION_AMOUNT_NOT_ALLOWED,
         INVALID_CARD_DATA,
         NOT_ALLOWED,
         CONTACT_YOUR_CARD_CENTER,
         TRANSACTION_N_ALLOWED,
         ESTABLISHMENT_CONTACT 
} from '../../models/paymentErroCode'
import { Undefined, UnderAnalysis, Declined, Approved } from '../../models/riskAnalysisStatusCode'
import { maskDateOfBirth, validateDateOfBirth, convertDateOfBirthToIsoFormat } from '../../utils/dateOfBirth.js'

const $ = window.$
const FULLY_AUTHENTICATED_STATUS = 1;
const ATTEMPT_STATUS = 4;
const AVAILABLE_PARTNER_PIXEL_CAMPAIGN = 'b2b-pja-online';
const PIXEL_ID = '743164896015033';
const MAX_CARD_AMOUNT = 9;
const MIN_CARD_VALUE = 10.0;

export class Payment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cards: [],
      isMultiplecards: false,
      allFieldsValidated: false,
      cardFlipped: false,
      paymentLinkData: null,
      paymentValidations: null,
      quotation: null,
      bankSlip: null,
      bankSlips: [],
      selectedConditionId: '',
      selectedCondition: null,
      switchMultipleCardIsDisable: true,
      settlementRequest: null,
      captureSettlementRequest: null,
      settlementResponse: null,
      acceptedTerms: false,
      timeToExpirePayment: '',
      timeToExpirePaymentInterval: null,
      tentativesCount: 3,
      loadingPage: true,
      processingPayment: false,
      showPaymentErrorMessage: false,
      paymentErrorMessage: '',
      showPaymentResult: false,
      paymentResultType: SUCCESS_RESULT,
      checkoutConfig: null,
      isFormSubmitted: false,
      tdsAuthenticationResponse: null,
      iframeSrcDoc: null,
      tdsResult: null,
      hasFinishedTdsAuthentication: false,
      currentStep: 1,
      checkedMoreCards: false,
      iconTogle: 'up',
      nameInvalidManssage: 'Nome inválido',
      erroCards: [],
      erroMenssageCredor: null,
      blockedBins: null,
      blockedBinMessage: null,
      cardNumberInvalidMessage: null,
      checkTermsAccepted: false
    }

    this.createBlankCards(MAX_CARD_AMOUNT);
    this.getPaymentLinkData = this.getPaymentLinkData.bind(this)
    this.floatToCurrency = this.floatToCurrency.bind(this)
    this.onChangeCondition = this.onChangeCondition.bind(this)
    this.onClickDoPayment = this.onClickDoPayment.bind(this)
    this.getSelectedCondition = this.getSelectedCondition.bind(this)
    this.initPixel = this.initPixel.bind(this)
  }

  async componentDidMount() {
    await this.getPaymentLinkData()
    this.setTimeToExpireQuotation()
    this.initPixel()
  }

  async getPaymentLinkData() {
    const { match: { params } } = this.props
    let checkoutConfig = null

    try {
      checkoutConfig = await getCheckoutSetup(params.partnerName)
      const paymentData = await getPaymentData(params.paymentLinkGuid, params.partnerName)

        //dados nulos, URL incorreta
        if (!paymentData.paymentLink || paymentData.status === 400) {
            this.setState({ paymentResultType: ERROR_LINK, loadingPage: false, showPaymentResult: true, checkoutConfig });
            return;
        }

      const quotation = paymentData.quotation
      const bankSlip = quotation && quotation.bankSlips[0]
      const bankSlips = quotation.bankSlips
      const paymentLinkData = paymentData.paymentLink
      const paymentValidations = paymentData.validations
      const paymentStatus = paymentLinkData.statusCode
      const selectedCondition = this.getSelectedCondition(quotation, paymentLinkData)
      const blockedBins = checkoutConfig.blockedBins
      const blockedBinMessage = checkoutConfig.blockedBinMessage
      const bankSlipPaid = paymentData.settlementStatus === null ? false : paymentData.settlementStatus.bankSlipPaid
      const riskAnalysisStatus = paymentData.settlementStatus === null ? Undefined : paymentData.settlementStatus.riskAnalysisStatus

        // cnpj do link diferente do cadastro backstage
        if (paymentData.paymentLink.merchantId !== checkoutConfig.document) {
            this.setState({ paymentResultType: ERROR_CONFIG_CNPJ, loadingPage: false, showPaymentResult: true, checkoutConfig })
            return;
        }
        //status 3
        if (paymentStatus === EXPIRED_STATUS) {
            //extended status 2
            this.setState({ paymentResultType: LINK_EXPIRED_RESULT, loadingPage: false, showPaymentResult: true, checkoutConfig })
            return;
        }
        //status 2
        if (paymentStatus === PAID_STATUS) {
            //extended status 4
            if (riskAnalysisStatus === Declined) {
                this.setState({ paymentResultType: PAYMENT_DECLINED, loadingPage: false, showPaymentResult: true, checkoutConfig })
                return;
            }
            //extend status 8
            if (bankSlipPaid) {
                this.setState({ paymentResultType: PAYMENT_APPROVED, loadingPage: false, showPaymentResult: true, checkoutConfig, paymentLinkData })
                return;
            }
            //extended status 6
            if (paymentValidations) {
                let validationsCompleted = paymentValidations.filter(function(paymentValidations) {
                    return paymentValidations.status == 'VALIDATED'
                })
                if (validationsCompleted.length == paymentValidations.length) {
                    this.setState({ paymentResultType: PIN_VALIDATED, loadingPage: false, showPaymentResult: true, checkoutConfig, paymentLinkData: paymentLinkData, paymentValidations: paymentValidations })
                    return;
                }
                this.setState({ paymentResultType: PAYMENT_WAITING_PIN_VALIDATION, loadingPage: false, showPaymentResult: true, checkoutConfig, paymentLinkData: paymentLinkData, paymentValidations: paymentValidations })
                return;
            }
            //extended status 3, 5 e 7
            if (riskAnalysisStatus === UnderAnalysis || riskAnalysisStatus === Approved || riskAnalysisStatus === Undefined) {
                this.setState({ paymentResultType: PAYMENT_IN_ANALYSIS, loadingPage: false, showPaymentResult: true, checkoutConfig, paymentLinkData })
                return;
            }
        }
        //status 3
        if (paymentStatus === OPEN_STATUS) {
            // boleto não registrado
            if (!bankSlip.recipientDocument && !bankSlip.recipientName && bankSlip.status === UNPAYABLE) {
                this.setState({ paymentResultType: NOT_REGISTERED_RESULT, loadingPage: false, showPaymentResult: true, checkoutConfig });
                return;
            }
            // CNPJ Fora da trava
            if (checkoutConfig.authorizedMerchants.length > 0 && this.checkAuthorizationError(bankSlips, checkoutConfig.authorizedMerchants)) {
                this.setState({ paymentResultType: ERROR_RESULT, loadingPage: false, showPaymentResult: true, checkoutConfig });
                return;
            }
            //extended status 1
            this.setState({ loadingPage: false, paymentLinkData, quotation, bankSlip, bankSlips, selectedCondition, selectedConditionId: selectedCondition.id, checkoutConfig, blockedBins: blockedBins, blockedBinMessage: blockedBinMessage });
            return;
        }
        //status 4 && extended status 50
        if (paymentStatus === MISSING_REGISTRY_BANKSLIP_CNPJ_STATUS) {
            this.setState({ paymentResultType: ERROR_RESULT, loadingPage: false, showPaymentResult: true, checkoutConfig });
            return;
        }

        //erro
        this.setState({ paymentResultType: ERROR_RESULT, loadingPage: false, showPaymentResult: true, checkoutConfig });

    } catch (error) {
      console.log(error);
      this.setState({ loadingPage: false, showPaymentResult: true, paymentResultType: ERROR_RESULT, checkoutConfig });
    }
  }
  
  checkAuthorizationError = (bankSlips, authorizedMerchants) => {
    let authorizeError = false
    
    Object.values(bankSlips).map((item) => {
      if(!this.isConcessionaire(item.barcode) && !authorizedMerchants.includes(item.recipientDocument))
      authorizeError = true
    })

    return authorizeError
  }

  setTimeToExpireQuotation = () => {
    const { timeToExpirePaymentInterval } = this.state

    let count = 25 * 60

    var myInterval = this.setCountdown(count, () => {
      $('#timeExpiredModal').modal('show')
      clearInterval(timeToExpirePaymentInterval)
    })

    this.setState({ timeToExpirePaymentInterval: myInterval })
  }

  initPixel() {
    const { match: { params } } = this.props
    const options = {
      autoConfig: true,
      debug: true,
    }

    if (params.partnerName !== AVAILABLE_PARTNER_PIXEL_CAMPAIGN) { return }

    ReactPixel.init(PIXEL_ID, null, options)
    ReactPixel.pageView()
  }

  getSelectedCondition(quotation, paymentLinkData) {
    const checkout = paymentLinkData.checkout
    const conditions = quotation.conditions

    if (conditions === null) { return null }

    if (checkout.installments !== null) {
      return conditions.find(condition => condition.installments === checkout.installments)
    }

    return conditions[0]
  }

  async onClickDoPayment() {
    const { match: { params } } = this.props
    const {
      quotation,
      paymentLinkData,
      selectedCondition,
      checkoutConfig,
      hasFinishedTdsAuthentication,
      tdsResult,
      tdsAuthenticationResponse,
      isMultiplecards
    } = this.state

    this.setState({ processingPayment: true, isFormSubmitted: true })

    if (!this.isValidForm()) {
      this.setState({ processingPayment: false })
      return
    }

    if (checkoutConfig.hasThreeDomainSecure && hasFinishedTdsAuthentication === false && !isMultiplecards) {
      await this.authenticateTds()
      return
    }

    const formattedCards = this.getFormattedCardsObjects();

    const settlementRequest = {
      quotationId: quotation.id,
      installments: selectedCondition.installments,
      conditionId: selectedCondition.id,
      partnerName: params.partnerName,
      name: paymentLinkData.payer.name,
      cpf: paymentLinkData.payer.document,
      email: paymentLinkData.payer.email,
      telephone: paymentLinkData.payer.phoneNumber,
      amountInCents: selectedCondition.totalAmount,
      cards: formattedCards,
      clearSaleSessionId: this.getClearSaleSessionId(),
      threeDS: (checkoutConfig.hasThreeDomainSecure === false || isMultiplecards)
        ? null
        : {
          CAVV: tdsResult.cardholderAuthenticationVerificationValue,
          transactionId: tdsResult.dsTransactionId,
          uniqueTransactionId: tdsAuthenticationResponse.uniqueTransactionId,
          status: tdsResult.status
        }
    }

    this.setState({ settlementRequest, showPaymentErrorMessage: false })

    try {

      const settlementResponse = await settleQuotation(params.paymentLinkGuid, settlementRequest)

        if (settlementResponse.success) {

          const dataLayer = {
            quotationId: quotation.id,
            successMessage: 'Transação aprovada. Normal',
            event: 'doPayment',
          }

          this.setState({ showPaymentResult: true, paymentResultType: PAYMENT_IN_ANALYSIS, hasFinishedTdsAuthentication: false, tdsResult: null, processingPayment: false })

        } else {

          let erroCards = []

          if(settlementResponse.unavailableCards){
            const lastId = settlementResponse.unavailableCards.length - 1
            settlementResponse.unavailableCards.map(i => {
              const card = this.state.cards.filter(card => card.cardId === i.cardId)
              const erroMessageCard = this.getMessageErroCard(i.messageCode, i.message)
              let cardObject = this.getCardObjectById(card[0].cardId);
              cardObject['hasErroCard'] = true;
              erroCards.push({
                cardId: card[0].cardId,
                cardBrand: card[0].cardBrand,
                finalNumber: card[0].finalNumber,
                erroMessage: erroMessageCard,
                lastCard: card[0].cardId === lastId? true : false
              })

              this.setState({ cards: this.state.cards })
            })
          } 
          
          this.setState({
            showPaymentErrorMessage: true,
            hasFinishedTdsAuthentication: false,
            paymentErrorMessage: settlementResponse.message? settlementResponse.message : DEFAULT_MESSAGE,
            tdsResult: null,
            processingPayment: false,
            currentStep: 2,
            erroCards: erroCards
          }, () => $('#transactionErrorModal').modal('show'))

          return
        }
    } catch (error) {

      const dataLayer = { quotationId: quotation.id, errorMessage: DEFAULT_MESSAGE, event: 'doPayment' }

      $('#transactionErrorModal').modal('show')
      this.setState({
        processingPayment: false,
        showPaymentErrorMessage: true,
        paymentErrorMessage: dataLayer.errorMessage,
        hasFinishedTdsAuthentication: false,
        tdsResult: null,
        currentStep: 2
      })
    }
  }

  getClearSaleSessionId() {
    return document.getElementById('clearSaleSessionId').value
  }

  isConcessionaire(barcode) {
    return barcode?.startsWith('8') ?? false
  }

  getMessageErroCard(messageCode, defaultMessage) {
    const listTryAnotherCard = this.getListTryAnotherCard()

    if (listTryAnotherCard.includes(messageCode))
      return TRY_ANOTHER_CARD
    else if (messageCode === ESTABLISHMENT_CONTACT)  
      return CARD_NOT_APPROVED
    else 
      return defaultMessage      
  }

  getListTryAnotherCard() {
    return [DEFAULT_ERRO,
            TRANSACTION_NOT_ALLOWED, 
            CONTACT_CARD_CENTER,
            INVALID_INSTALLMENT,
            NOT_ALLOWED_CARD ,
            CARD_ERROR,
            INVALID_ESTABLISHMENT,
            TRANSACTION_AMOUNT_NOT_ALLOWED,
            INVALID_CARD_DATA,
            NOT_ALLOWED,
            CONTACT_YOUR_CARD_CENTER,
            TRANSACTION_N_ALLOWED,
            ESTABLISHMENT_CONTACT]
  }

  authenticateTds = async () => {
    try {
      const {
        selectedCondition,
        cards,
        quotation
      } = this.state

      const card = cards[0];
      const pan = card.number.replace(/\s/g, '')
      const splitDate = card.expirationDate.split('/')
      const expiry = `${splitDate[1]}${splitDate[0]}`

      const tdsRequest = {
        quotationId: quotation.id,
        pan: pan,
        expiry: expiry,
        purchaseAmount: selectedCondition.totalAmount
      }

      const response = await tdsAuthenticate(tdsRequest)
      this.setState({ iframeSrcDoc: response.authenticationPage, tdsAuthenticationResponse: response }, () => $('#tdsModal').modal('show'))

      setTimeout(this.getTdsResult, 500)
    } catch (error) {
      this.setState({
        processingPayment: false,
        hasFinishedTdsAuthentication: true,
        showPaymentErrorMessage: true,
        paymentErrorMessage: 'Ocorreu um erro ao realizar a autenticação da transação com o banco. Por favor, verifique os dados e tente novamente.'
      }, () => $('#transactionErrorModal').modal('show'))
    }
  }

  getTdsResult = async () => {
    const { tdsAuthenticationResponse, quotation } = this.state

    const result = await getTdsResult(tdsAuthenticationResponse.uniqueTransactionId, quotation.id)

    if (this.isNil(result)) {
      setTimeout(this.getTdsResult, 500)
      return
    }

    this.onGetTdsResultSuccess(result)
  }

  onGetTdsResultSuccess = (response) => {
    if (response.status === FULLY_AUTHENTICATED_STATUS || response.status === ATTEMPT_STATUS) {
      $('#tdsModal').modal('hide')
      this.setState({ tdsResult: response, hasFinishedTdsAuthentication: true }, () => this.onClickDoPayment())
    } else {
      $('#tdsModal').modal('hide')
      this.setState({
        processingPayment: false,
        hasFinishedTdsAuthentication: false,
        showPaymentErrorMessage: true,
        paymentErrorMessage: 'Transação não autenticada pelo banco emissor do cartão.'
      }, () => $('#transactionErrorModal').modal('show'))
    }
  }
  
  floatToCurrency = (value) => {
    return ((!value ? 0.0 : value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })).replace(' ', '')
  }

  setCountdown = (count, callback) => {
    return setInterval(() => {
      count--

      var minutes = Math.floor(count / 60)
      var seconds = count - minutes * 60

      this.setState({ timeToExpirePayment: `${minutes}:${this.pad(seconds, 2)}` })

      if (count === 0) {
        callback()
      }
    }, 1000)
  }

  pad = (value, max) => {
    value = value.toString()

    return value.length < max ? this.pad('0' + value, max) : value
  }

  isNil = (value) => {
    return value === '' || value === null || value === undefined
  }

  isValidForm = () => {
    const {
      cards,
      isMultiplecards,
      selectedConditionId
    } = this.state

    var isValid = true;
    cards.filter(item => item.isEnabled).forEach(item => {
      if (item.number === '' ||
        item.expirationDate === '' ||
        item.cvv === '' ||
        selectedConditionId === '' ||
        (isMultiplecards && item.value < MIN_CARD_VALUE)
      ) {
        isValid = false;
      }
    });

    return isValid;
  }

  pushDoPaymentTracking = (paymentSuccess) => {
    const { selectedCondition } = this.state
    const { match: { params } } = this.props

    if (params.partnerName !== AVAILABLE_PARTNER_PIXEL_CAMPAIGN) { return }

    ReactPixel.trackCustom('ClickDoPayment', { success: paymentSuccess, total_amount: selectedCondition.totalAmount })
  }

  requestPinValidation = async (merchantId, id, paymentLinkGuid) => {
    try {
      const quotationValues = { MerchantId: merchantId, QuotationId: id }
      const AutomaticValidationPinResponse = await settleAutomaticValidation(paymentLinkGuid, quotationValues)
      if (AutomaticValidationPinResponse.authorizers.length > 0) {
        const paymentValidations = AutomaticValidationPinResponse.authorizers
        this.setState({ showPaymentResult: true, paymentResultType: PAYMENT_WAITING_PIN_VALIDATION, hasFinishedTdsAuthentication: false, tdsResult: null, paymentValidations: paymentValidations, processingPayment: false })
        $('#ModalPaymentInProcess').modal('show')
      } else {  
        this.setState({ showPaymentResult: true, paymentResultType: PAYMENT_IN_ANALYSIS, hasFinishedTdsAuthentication: false, tdsResult: null, processingPayment: false })
      }
      return
    } catch (error) {
      this.setState({ 
        showPaymentResult: true, 
        paymentResultType: PAYMENT_IN_ANALYSIS, 
        hasFinishedTdsAuthentication: false, 
        tdsResult: null,
       processingPayment: false 
      })
    }
  }

  createBlankCards(amount) {
    for (var i = 0; i < amount; i++) {
      this.state.cards.push(
        {
          cardId: i,
          cardBrand: '',
          holderName: '',
          holderDocument: '',
          holderDateOfBirth: '',
          number: '',
          cvv: '',
          expirationDate: '',
          isEnabled: i === 0 ? true : false,
          value: 0.0,
          holderPhoneNumber: '',
          holderEmail: '',
          kinship: '',
          validField: {
            validNumber: '',
            validHolderName: '',
            validExpirationDate: '',
            validCvv: '',
            validHolderDocument: '',
            validHolderPhoneNumber: '',
            validholderEmail: '',
            validKinship: ''
          }
        }
      )
    }
  }

  getCardObjectById = (cardId) => this.state.cards.find(item => item.cardId === cardId);

  switchMultiplePayments(isMultiple) {
    // this.cleanAllCards();
    this.cleanCardsExceptFirst();
    this.setState({ isMultiplecards: isMultiple });
  }

  cleanCardsExceptFirst() {
    this.state.cards.forEach(item => {
      if (item.cardId !== 0) {
        item.cardBrand = '';
        item.holderName = '';
        item.holderDocument = '';
        item.holderDateOfBirth = '';
        item.number = '';
        item.cvv = '';
        item.expirationDate = '';
        item.isEnabled = false;
        item.value = null;
        item.finalNumber = '';
        item.hasErroCard = false;
        item.holderPhoneNumber = '';
        item.holderEmail = '';
        item.validField.validNumber = '';
        item.validField.validHolderName = '';
        item.validField.validExpirationDate = '';
        item.validField.validCvv = '';
        item.validField.validHolderDocument = '';
        item.validField.validHolderPhoneNumber = '';
        item.validField.validHolderEmail = '';
        item.validField.validKinship = '';
      }
    })

    this.state.cards[0].value = null
  }

  cleanAllCards() {
    this.state.cards = [];
    this.createBlankCards(MAX_CARD_AMOUNT);
  }

  removeCard = (cardId) => {
    var cardObject = this.getCardObjectById(cardId);

    cardObject.cardBrand = '';
    cardObject.holderName = '';
    cardObject.holderDocument = '';
    cardObject.holderDateOfBirth = '';
    cardObject.number = '';
    cardObject.cvv = '';
    cardObject.expirationDate = '';
    cardObject.isEnabled = false;
    cardObject.value = null;
    cardObject.finalNumber = '';
    cardObject.hasErroCard = false;
    cardObject.holderPhoneNumber = '';
    cardObject.holderEmail = '';
    cardObject.validField.validNumber = '';
    cardObject.validField.validHolderName = '';
    cardObject.validField.validExpirationDate = '';
    cardObject.validField.validCvv = '';
    cardObject.validField.validHolderDocument = '';
    cardObject.validField.validKinship = '';
    cardObject.validField.validHolderPhoneNumber = '';
    cardObject.validField.validHolderEmail = '';

    this.setState({ cards: this.state.cards })
  }

  addCard = () => {
    var nextUnusedCard = this.state.cards.filter(item => !item.isEnabled)[0];
    if (nextUnusedCard) {
      nextUnusedCard.isEnabled = true
      this.setState({ cards: this.state.cards });
    }
  }

  getPaidValue = () => {
    let value = 0.0;
    this.state.cards.forEach(item => {
      if (item.isEnabled) {
        value = currency(value).add(this.formatMoneyToNumber(item.value))
      }
    });
    return value
  }

  getRemainingValue = () => {
      if (this.state.selectedCondition) {
        var totalReais = currency(this.state.selectedCondition.totalAmount).divide(100)
        var result = totalReais.subtract(this.getPaidValue())

        return result
      }

    return 0.0;
  }

  hasEmptyCardEnabled = () => {
    var hasEmpty = false;
    this.state.cards.forEach(item => {
      if (item.isEnabled && item.value === 0) {
        hasEmpty = true
      }
    });
    return hasEmpty;
  }

  getFormattedCardsObjects = () => {
    const { cards, isMultiplecards, selectedCondition } = this.state;
    var formattedObjects = [];
    if (isMultiplecards) {
      var enabledCards = cards.filter(item => (item.isEnabled && item.value > 0.0));
      enabledCards.forEach(item => {
        formattedObjects.push({
          id: item.cardId,
          holderName: item.holderName,
          holderDocument: unformatValue(item.holderDocument),
          holderDateOfBirth: convertDateOfBirthToIsoFormat(item.holderDateOfBirth),
          number: item.number,
          cvv: item.cvv,
          expirationDate: item.expirationDate,
          amount: Math.trunc(item.value * 100),
          kinship: item.kinship,
          holderPhoneNumber: unformatValue(item.holderPhoneNumber),
          holderEmail: item.holderEmail
        })
      })
    } else {
      formattedObjects.push({
        holderName: cards[0].holderName,
        holderDocument: unformatValue(cards[0].holderDocument),
        holderDateOfBirth: convertDateOfBirthToIsoFormat(cards[0].holderDateOfBirth),
        number: cards[0].number,
        cvv: cards[0].cvv,
        expirationDate: cards[0].expirationDate,
        amount: selectedCondition.totalAmount,
        kinship: cards[0].kinship,
        holderPhoneNumber: unformatValue(cards[0].holderPhoneNumber),
        holderEmail: cards[0].holderEmail
      })
    }
    return formattedObjects;
  }

  handleChangeCheckedMoreCards = (event) => {
    this.switchMultiplePayments(event.target.checked)
    this.setState({ checkedMoreCards: event.target.checked }, () => this.validAllFields())
  }

  onChangeAndValidInputField = (stateName, cardId, event) => {
    const value = event.target.value
    var cardObject = this.getCardObjectById(cardId);

    switch (stateName) {
      case 'number':
        const cardNumber = value.replace(/\s/g, '')
        const cardBrand = getCardType(cardNumber)
        const validCardNumber = this.validCardNumberLuhn(value)
        const validBinCardNumber = this.validateBIN(cardNumber)
        let cardNumberInvalidMessage = 'Número inválido'
        cardObject['number'] = value;
        cardObject['cardBrand'] = cardBrand;
        cardObject['finalNumber'] = value.slice(-4);
        if(!validBinCardNumber){
          cardObject['validField']['validNumber'] = 'is-invalid'
          cardNumberInvalidMessage = this.state.blockedBinMessage
        } else if(cardNumber.length < 12){
          cardObject['validField']['validNumber'] = 'is-invalid'
        } else if(!validCardNumber) {
          cardObject['validField']['validNumber'] = 'is-warning'
        } else {
          cardObject['validField']['validNumber'] = 'is-valid'
        }
        cardObject['hasErroCard'] = false;
        this.setState({ cardNumberInvalidMessage: cardNumberInvalidMessage })
        break;
      case 'holderName':
        cardObject['holderName'] = value
        const invalidValueNameBank = this.validBankListNamesExcluides(value, BANKS_LIST);
        const validSurname = /^[A-Za-zÀ-ú]+( [A-Za-zÀ-ú]+)+/.test(value);
        var nameInvalidManssage = ''
        if (invalidValueNameBank) {
          cardObject['validField']['validHolderName'] = 'is-invalid'
          nameInvalidManssage = 'Você digitou o nome do emissor do cartão, preencha com o seu nome!'
        } else if (!validSurname) {
          cardObject['validField']['validHolderName'] = 'is-invalid'
          nameInvalidManssage = 'Nome inválido'
        } else {
          cardObject['validField']['validHolderName'] = 'is-valid'
        }
        cardObject['hasErroCard'] = false;
        this.setState({ nameInvalidManssage: nameInvalidManssage })
        break;
      case 'expirationDate':
        cardObject['expirationDate'] = value
        const validExpirationDateField = this.validExpirationDateFunction(value)
        if (!validExpirationDateField)
          cardObject['validField']['validExpirationDate'] = 'is-invalid'
        else
          cardObject['validField']['validExpirationDate'] = 'is-valid'
        cardObject['hasErroCard'] = false;
        break;
      case 'cvv':
        cardObject['cvv'] = value
        cardObject['validField']['validCvv'] = value.length < 3 ? 'is-invalid' : 'is-valid'
        cardObject['hasErroCard'] = false;
        break;
      case 'holderDocument':
        cardObject['holderDocument'] = maskCpfCnpj(value)
        cardObject['validField']['validHolderDocument'] = validateCpfCpnj(value) ? 'is-valid' : 'is-invalid'
        cardObject['hasErroCard'] = false;
            break;
        case 'kinship':
        cardObject['kinship'] = value
        cardObject['validField']['validKinship'] = value.length > 0 ? 'is-valid' : 'is-invalid'
        cardObject['hasErroCard'] = false;
        break;
      case 'holderPhoneNumber':
        cardObject['holderPhoneNumber'] = maskPhone(value)
        cardObject['validField']['validHolderPhoneNumber'] = (value.length < 14 || value.length > 15) ? 'is-invalid' : 'is-valid'
        cardObject['hasErroCard'] = false;
        break;
      case 'holderEmail':
        cardObject['holderEmail'] = value
        cardObject['validField']['validHolderEmail'] = value.length > 50 ? 'is-invalid' : 'is-valid'
        cardObject['hasErroCard'] = false;
        break;
      case 'value':
        cardObject['value'] = value
        cardObject['hasErroCard'] = false;
        break;
      default:
    }

    this.setState({ cards: this.state.cards, showPaymentErrorMessage: false }, () => this.validAllFields())
  }

  validateBIN = (value) => {
    const blockedBins = this.state.blockedBins
    const initialNumber = value.toString().substr(0, 6)

    let validBIN = true
    blockedBins.map((item) => {
      if(item === initialNumber)
        validBIN = false
    })

    return validBIN
  }

  validBankListNamesExcluides = (name, BankList) => {
    const arrayName = name.split(" ")
    let invalidName = false
    let intersection 

    BankList.forEach( bankName => {
      intersection = arrayName.filter(item => item.localeCompare(bankName, 'pt', { sensitivity: 'base' }) === 0)
      if(intersection.length > 0)
        invalidName = true
    })

    return invalidName
  }

  validCardNumberLuhn = (cardNumber) => {
    let nCheck = 0;
    if (cardNumber && /[0-9-\s]+/.test(cardNumber)) {
        cardNumber = cardNumber.replace(/\D/g, '');
        cardNumber.split('').forEach((digit, index) => {
            let nDigit = parseInt(digit, 10);
            if (!((cardNumber.length + index) % 2) && (nDigit *= 2) > 9) {
                nDigit -= 9;
            }
            nCheck += nDigit;
        });
    }

    return (nCheck % 10) === 0;
  }

  validAllFields = () => {
    const cardsEnabled = this.state.cards.filter(item => item.isEnabled === true);

    const allValidFields = cardsEnabled.map(item => {

      const validFields = [
        item['validField']['validNumber'],
        item['validField']['validHolderName'],
        item['validField']['validExpirationDate'],
        item['validField']['validCvv'],
        item['validField']['validHolderDocument'],
        item['validField']['validHolderPhoneNumber'],
        item['validField']['validHolderEmail'],
        item['validField']['validKinship']
      ]

      return (validFields.includes('is-invalid') || validFields.includes('')) ? false : true
    })

    if (this.state.isMultiplecards) {
      allValidFields.push(this.getRemainingValue() > 0 ? false : true)
    }

    this.setState({ allFieldsValidated: allValidFields.includes(false) ? false : true })
  }

  formatMoneyToNumber = (value) => {
    return currency(value, { decimal: ',', separator: '.' }).value
  }

  onBlurValueInput(cardId, event) {
    var cardObject = this.getCardObjectById(cardId);
    const value = this.formatMoneyToNumber(event.target.value);
    const remainingValue = (this.getRemainingValue().add(value)).value;
    var fixedValue = value < MIN_CARD_VALUE ? MIN_CARD_VALUE : value;
    cardObject['value'] = fixedValue <= remainingValue ? fixedValue : remainingValue
    this.setState({
      cards: this.state.cards,
      showPaymentErrorMessage: false
    }, () => this.validAllFields())
  }

  validExpirationDateFunction = (value) => {

    const splitValue = value.split('/')
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear().toString().slice(-2)
    const currentMonth = currentDate.getMonth() + 1

    if (splitValue[1] === currentYear && splitValue[0] < currentMonth) return false
    else if (splitValue[1] < currentYear || splitValue[1] > 70 || splitValue[0] > 12 || splitValue[0] <= 0) return false
    else return true
  }

  toStep = (n) => {

    switch (n) {
      case 1:
        this.state.currentStep = 1; break
      case 2:
        this.state.currentStep = 2; break
      case 3:
        this.state.currentStep = 3; break
      default:
        this.state.currentStep = 1; break
    }

    this.setState({ currentStep: this.state.currentStep })
  }

  handleCheckboxTermsChange = () => {
    this.setState({ checkTermsAccepted: !this.state.checkTermsAccepted });
  }

  onChangeCondition = (conditionSelected) => {
    const { quotation } = this.state

    const selectedConditionId = conditionSelected
    const selectedCondition = quotation.conditions.find(condition => condition.id === conditionSelected)

    this.setState({
      selectedConditionId,
      selectedCondition,
      showPaymentErrorMessage: false
    }, () => this.onChangeConditionInstallmentsValid(selectedCondition.installments))
  }

  onChangeConditionInstallmentsValid = (selectedCondition) => {
    if(selectedCondition > 12) {
      this.switchMultiplePayments(false)
      this.setState({ checkedMoreCards: false })
    }
  }

  validInstallmentsLimitToMultipleCards = (selectedCondition) => {
    return selectedCondition > 12 ? true : false 
  }

  render() {
    const {
      isMultiplecards,
      cards,
      paymentLinkData,
      paymentValidations,
      quotation,
      bankSlip,
      bankSlips,
      selectedConditionId,
      selectedCondition,
      loadingPage,
      processingPayment,
      paymentErrorMessage,
      showPaymentResult,
      paymentResultType,
      checkoutConfig,
      isFormSubmitted,
      iframeSrcDoc,
      selfLink,
      currentStep,
      checkedMoreCards,
      nameInvalidManssage,
      erroCards,
      erroMenssageCredor,
      checkTermsAccepted
    } = this.state;

    const conditions = quotation ? quotation.conditions : ''
    const maxCardsReached = this.state.cards.filter(item => item.isEnabled).length === MAX_CARD_AMOUNT
    const hasEmptyCardEnabled = this.hasEmptyCardEnabled()
    const remainingValue = this.getRemainingValue()

    return (
      <>
        <div className="box" translate='no'>
          {!loadingPage && <NavMenu checkoutConfig={checkoutConfig ? checkoutConfig : null} />}
          {showPaymentResult && <>
            <PaymentResult type={paymentResultType} checkoutConfig={checkoutConfig} selfLink={selfLink} receiptEmail={paymentLinkData ? paymentLinkData.payer.email : ''} paymentValidations={paymentValidations ? paymentValidations : null} erroMenssageCredor={erroMenssageCredor} />
            <div>
              {(paymentResultType === ERROR_RESULT || paymentResultType === NOT_REGISTERED_RESULT) &&
                <div className='box-button-step-reload'>
                    <button id='button-reload-footer' type="button" className="btn btn-step" onClick={() => window.location.reload()}>RECARREGAR A PÁGINA</button>
                </div>
              }
              <Footer checkoutConfig={checkoutConfig ? checkoutConfig : null} paymentResultType={this.state.paymentResultType ? this.state.paymentResultType : null}  />
            </div>
          </>}

          {!showPaymentResult && <>
            {(!loadingPage && checkoutConfig) && <>
              {window.innerWidth < 991 && <div className='steps container'>
                <div className='steps-row row'>
                  <div className='col number-col'>
                    <div style={{ display: 'flex' }}>
                      <div className='number-circle-active'>1</div>
                      <hr className={`${currentStep !== 1 ? 'line-step-active' : 'line-step-disabled'}`} />
                    </div>
                    <span className='number-circle-text'>Informações gerais</span>
                  </div>
                  <div className='col number-col'>
                    <div style={{ display: 'flex' }}>
                      <div className={`${currentStep !== 1 ? 'number-circle-active' : 'number-circle-disable'}`} >2</div>
                      <hr className={`${currentStep === 3 ? 'line-step-active' : 'line-step-disabled'}`} />
                    </div>
                    <span className='number-circle-text'>Pagamento</span>
                  </div>
                  <div className='col number-col'>
                    <div className={`${currentStep === 3 ? 'number-circle-active' : 'number-circle-disable'}`} >3</div>
                    <span className='number-circle-text'>Resumo pagamento</span>
                  </div>
                </div>
              </div>}
              <div className='box-infos'>
                <div className="box-container container">
                  {(!loadingPage && checkoutConfig) && <div className="box-row row">
                    {(currentStep === 1 || window.innerWidth > 991) &&
                      <div className={`general-info col`}>
                        <div>
                          <div className='general-info-title'>Informações gerais</div>
                          <div className='general-info-payer'>
                            <span className='general-info-payer-title'>Pagador</span>
                            <div className='general-info-payer-data'>
                              <span className='span-text'>Nome</span>
                              <span className='span-info'>{paymentLinkData.payer.name}</span>
                            </div>
                            <div className='general-info-payer-data'>
                              <span className='span-text'>CPF</span>
                              <span className='span-info'>{ toCpfOrCnpj(paymentLinkData.payer.document) }</span>
                            </div>
                          </div>
                          <div className='general-info-payer'>
                            <span className='general-info-payer-title'>Beneficiário</span>
                            <div className='general-info-payer-data'>
                              <span className='span-text'>Nome</span>
                              {bankSlips.length > 1 ? 
                                <>
                                <button id='button-show-recipents' type="button" className={`button-show-recipents`} data-toggle="modal" data-target="#ModalShowRecipents">
                                  <span className='span-info'>Vários Beneficiários</span>
                                </button>
                                </>
                                :
                                <>
                                  <span className='span-info'>{bankSlip?.recipientName ?? '-'}</span>
                                </>
                              }
                            </div>
                            <div className='general-info-payer-data'>
                              <span className='span-text'>CNPJ</span>
                              {bankSlips.length > 1 ? 
                                <>
                                <button id='button-show-recipents' type="button" className={`button-show-recipents`} data-toggle="modal" data-target="#ModalShowRecipents">
                                  <span className='span-info'>Vários Beneficiários</span>
                                </button>
                                </>
                                :
                                <>
                                  <span className='span-info'>{ toCpfOrCnpj(bankSlip.recipientDocument) }</span>
                                </>
                              }
                            </div>
                          </div>
                          <div className='general-info-payer'>
                            <span className='general-info-payer-title'>Dados da cobrança</span>
                            <div className='general-info-payer-data'>
                              <span className='span-text'>Detalhes</span>
                              <span className='span-info'>{paymentLinkData.details}</span>
                            </div>
                            <div className='general-info-payer-data'>
                              <span className='span-text'>Credor</span>
                              <span className='span-info'>{paymentLinkData.description}</span>
                            </div>
                          </div>
                        </div>
                        <div className='box-button-next-step-desktop'>
                          <span className={`disclaimer-info-payment`}>Realizar o pagamento até as 18h do dia {bankSlip && format(new Date(bankSlip.expirationDate), 'dd/MM/yyyy')}</span>
                        </div>
                      </div>}
                    {(currentStep === 2 || window.innerWidth > 991) &&
                      <div className={`payment-info col`}>
                        <div className='payment-info-box-1'>
                          <div className='payment-info-title'>Pagamento</div>
                          <div className="payment-info-values">
                            <div className="payment-info-value-total">Valor total: {this.floatToCurrency(selectedCondition.totalAmount / 100)}</div>
                            {/* Parcela Fechada */}
                            {paymentLinkData.checkout.installments !== null &&
                              <div className="payment-info-installments">Parcelas: {selectedCondition.installments}X de {this.floatToCurrency(selectedCondition.installmentAmount / 100)}</div>
                            }
                            <div>Cartão de crédito</div>
                            {/* Parcela Aberta */}
                            {paymentLinkData.checkout.installments === null &&
                              <>
                                {window.innerWidth > 991 &&
                                  <select className={`form-control select-installments ${(this.isNil(selectedConditionId) && isFormSubmitted) ? 'is-invalid' : ''}`} disabled={processingPayment} value={selectedConditionId} onChange={(event) => this.onChangeCondition(event.target.value)}>
                                    {conditions && conditions.map(condition => {
                                        return <option key={condition.id} value={condition.id}>{condition.installments}x {this.floatToCurrency(condition.installmentAmount / 100)}</option>
                                    })}
                                  </select>
                                }
                                {window.innerWidth < 991 &&
                                  <button id='button-modal-installments' type="button" disabled={processingPayment} className={`button-select-installments`} data-toggle="modal" data-target="#ModalSelectInstallments">
                                    <div>{selectedCondition.installments}x {this.floatToCurrency(selectedCondition.installmentAmount / 100)}</div>
                                    <FontAwesomeIcon icon={faAngleDown} />
                                  </button>
                                }
                              </>
                            }
                          </div>
                          <div className={`payment-info-card-opt ${selectedCondition.installments > 12? 'desable-payment-multiple' : ''}`}>
                            <span>Pagar com mais de um cartão</span>
                            <label className="switch">
                              <input type="checkbox" role="button" aria-expanded="false" id="collapseExample" disabled={processingPayment || this.validInstallmentsLimitToMultipleCards(selectedCondition.installments)} checked={checkedMoreCards} onChange={this.handleChangeCheckedMoreCards} />
                              <span className={`slider round`}></span>
                            </label>
                          </div>
                          {selectedCondition.installments > 12 &&
                            <div className="payment-info-min-installments">
                              <FontAwesomeIcon icon={faExclamationCircle}/>
                              <span>O parcelamento a partir de 13 vezes não está disponível para pagamento com multiplos cartões</span>
                            </div>
                          }
                        </div>

                        {this.state.cards.map(item => {

                          const {
                            isEnabled,
                            cardId,
                            cardBrand,
                            holderName,
                            number,
                            cvv,
                            expirationDate,
                            value,
                            holderDocument,
                            holderDateOfBirth,
                            holderPhoneNumber,
                            holderEmail,
                            kinship,
                            finalNumber,
                            hasErroCard
                          } = item;

                          const {
                            validNumber,
                            validHolderName,
                            validExpirationDate,
                            validCvv,
                            validHolderDocument,
                            validHolderPhoneNumber,
                            validHolderEmail,
                            validKinship
                          } = item.validField

                          if (isEnabled)
                            return (
                                <Fragment key={cardId}>
                                <div className="payment-info-card-add">
                                  <div className={`payment-info-card-add-square-fisrt ${hasErroCard? 'square-card-invalid' : ''}`}>
                                    <div className='payment-info-card-add-final'>{getImgLogoCard(cardBrand)} FINAL {finalNumber && finalNumber > 4 ? finalNumber : '****'}</div>
                                    <div style={{ display: 'flex' }}>
                                      {isMultiplecards && <div className="payment-info-card-add-value">{this.floatToCurrency(value)}</div>}
                                      <button id='buttton-collapse-multiple-cards' type='button' className='button-icon-collapse' data-toggle="collapse" href={"#collapseExample2" + cardId} role="button" aria-expanded="false" aria-controls="collapseExample2" onClick={() => this.setState({ iconTogle: this.state.iconTogle === 'down' ? 'up' : 'down' })} > <i className={`bi bi-chevron-compact-${this.state.iconTogle}`}></i></button>
                                    </div>
                                  </div>
                                </div>

                                  <div className="payment-info-card-add-colapse collapse show" id={"collapseExample2" + cardId}>

                                    <div className="form-group" >
                                      <NumberFormat
                                        format='#### #### #### ####'
                                        className={`form-control ${validNumber} ${(this.isNil(number) && isFormSubmitted) ? 'is-invalid' : ''}`}
                                        placeholder="Número do cartão"
                                        disabled={processingPayment}
                                        value={number}
                                        onChange={(event) => this.onChangeAndValidInputField('number', cardId, event)}
                                        onBlur={(event) => this.onChangeAndValidInputField('number', cardId, event)}
                                      /> 
                                      {(validNumber === 'is-invalid') && 
                                        <div className='invalid-span'>
                                          <span>{ this.state.cardNumberInvalidMessage }</span>
                                        </div>
                                      }
                                      {(validNumber === 'is-warning') && 
                                        <div className='invalid-span-number'>
                                          <FontAwesomeIcon icon={faExclamationCircle}/> 
                                          <span> Atenção: o número do cartão pode estar inválido</span>
                                        </div>
                                      }
                                    </div>

                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className={`form-control ${validHolderName} ${(this.isNil(holderName) && isFormSubmitted) ? 'is-invalid' : ''}`}
                                        placeholder="Nome impresso no cartão"
                                        disabled={processingPayment}
                                        maxLength={30}
                                        defaultValue={holderName}
                                        onChange={(event) => this.onChangeAndValidInputField('holderName', cardId, event)}
                                        onBlur={(event) => this.onChangeAndValidInputField('holderName', cardId, event)}
                                      />
                                      {(validHolderName === 'is-invalid') && <span className={`invalid-span`}>* {nameInvalidManssage}</span>}
                                    </div>

                                    <div className="form-group">
                                      <div className="form-row">
                                        <div className="col">
                                          <NumberFormat
                                            format='##/##'
                                            mask={['M', 'M', 'Y', 'Y']}
                                            className={`form-control ${validExpirationDate} ${(this.isNil(expirationDate) && isFormSubmitted) ? 'is-invalid' : ''}`}
                                            placeholder="Validade - MM/AA"
                                            disabled={processingPayment}
                                            value={expirationDate}
                                            onChange={(event) => this.onChangeAndValidInputField('expirationDate', cardId, event)}
                                            onBlur={(event) => this.onChangeAndValidInputField('expirationDate', cardId, event)}
                                          />
                                          {(validExpirationDate === 'is-invalid') && <span className={`invalid-span`}>* Data inválida</span>}
                                        </div>
                                        <div className="col">
                                          <input
                                            value={cvv}
                                            className={`form-control ${validCvv} ${(this.isNil(cvv) && isFormSubmitted) ? 'is-invalid' : ''}`}
                                            placeholder="CVV"
                                            disabled={processingPayment}
                                            maxLength={4}
                                            onChange={(event) => this.onChangeAndValidInputField('cvv', cardId, event)}
                                            onBlur={(event) => this.onChangeAndValidInputField('cvv', cardId, event)}
                                          />
                                          {(validCvv === 'is-invalid') && <span className={`invalid-span`}>* CVV inválido</span>}
                                        </div>
                                      </div>
                                    </div>
                                    {/* Campo Cpf */}
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        value={holderDocument}
                                        disabled={processingPayment}
                                        maxLength="18"
                                        className={`form-control ${validHolderDocument} ${(this.isNil(holderDocument) && isFormSubmitted) ? 'is-invalid' : ''}`}
                                        placeholder="CPF/CNPJ do portador do cartão"
                                        onChange={(event) => this.onChangeAndValidInputField('holderDocument', cardId, event)}
                                        onBlur={(event) => this.onChangeAndValidInputField('holderDocument', cardId, event)}
                                      />
                                      {(validHolderDocument === 'is-invalid') && <span className={`invalid-span`}>* CPF/CNPJ inválido</span>}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        value={holderPhoneNumber}
                                        disabled={processingPayment}
                                        className={`form-control ${validHolderPhoneNumber} ${(this.isNil(holderPhoneNumber) && isFormSubmitted) ? 'is-invalid' : ''}`}
                                        placeholder="Celular do portador do cartão"
                                        onChange={(event) => this.onChangeAndValidInputField('holderPhoneNumber', cardId, event)}
                                        onBlur={(event) => this.onChangeAndValidInputField('holderPhoneNumber', cardId, event)}
                                      />
                                      {(validHolderPhoneNumber === 'is-invalid') && <span className={`invalid-span`}>* Celular inválido</span>}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        value={holderEmail}
                                        disabled={processingPayment}
                                        className={`form-control ${validHolderEmail}`}
                                        placeholder="Email do portador do cartão"
                                        onChange={(event) => this.onChangeAndValidInputField('holderEmail', cardId, event)}
                                        onBlur={(event) => this.onChangeAndValidInputField('holderEmail', cardId, event)}
                                      />
                                      {(validHolderEmail === 'is-invalid') && <span className={`invalid-span`}>* E-mail inválido</span>}
                                    </div>  
                                    <div className="form-group">
                                      <select name="kinship" id="kinship"
                                          className={`form-control ${validKinship} ${(this.isNil(kinship) && isFormSubmitted) ? 'is-invalid' : ''}`}
                                          onChange={(event) => this.onChangeAndValidInputField('kinship', cardId, event)}
                                          onBlur={(event) => this.onChangeAndValidInputField('kinship', cardId, event)}
                                          disabled={processingPayment}                                              
                                          value={kinship}
                                      >
                                          <option value="" disabled defaultValue>Qual seu vínculo com o portador do cartão?</option>
                                          <option value="Própio">Sou o portador do cartão</option>
                                          <option value="Cônjuge">Cônjuge</option>
                                          <option value="Pai">Pai/Mãe</option>
                                          <option value="Filho">Filho(a)</option>
                                          <option value="Irmão/Irmã">Irmão/Irmã</option>
                                          <option value="Tio(a)">Tio(a)</option>
                                          <option value="Primo(a)">Primo(a)</option>
                                          <option value="Vô/Vó">Vô/Vó</option>
                                          <option value="Amigo(a)">Amigo(a)</option>
                                      </select>
                                      {(validKinship === 'is-invalid') && <span className={`invalid-span`}>* Selecione uma opção</span>}
                                    </div>
                                    {isMultiplecards &&
                                      <div className="form-group">
                                        <NumberFormat
                                          type="text"
                                          value={value}
                                          prefix={'R$ '}
                                          disabled={processingPayment}
                                          decimalSeparator={','}
                                          thousandSeparator={'.'}
                                          className={`form-control remove-border-radius ${(this.isNil(value) && isFormSubmitted) ? 'is-invalid' : ''}`}
                                          placeholder="Valor a ser pago no cartão"
                                          ref={el => this.el = el}
                                          onChange={(event) => this.onChangeAndValidInputField('value', cardId, event)}
                                          onBlur={(event) => this.onBlurValueInput(cardId, event)}
                                        />
                                      </div>
                                    }
                                    {(cardId > 0) &&
                                      <div className="box-button-delete-card">
                                        <button id='button-delete-card' type='button' className="payment-info-delete-card" onClick={() => this.removeCard(cardId)}><i className="bi bi-trash-fill" ></i>EXCLUIR CARTÃO</button>
                                      </div>
                                    }
                                  </div>
                              </Fragment>
                            )
                        })}
                        {(selectedCondition.installments < 13 && isMultiplecards) &&
                          <div>
                            <div className="payment-info-card-add-square">
                              <button id='button-remaining-amount' type='button' className={`payment-info-card-add-square-plus ${(maxCardsReached || hasEmptyCardEnabled || remainingValue === 0 || remainingValue < MIN_CARD_VALUE) ? 'btn-add-disabled' : ''}`} onClick={this.addCard} disabled={maxCardsReached || hasEmptyCardEnabled || remainingValue === 0 || remainingValue < MIN_CARD_VALUE || this.validInstallmentsLimitToMultipleCards(selectedCondition.installments)}>+ ADICIONAR CARTÃO</button>
                              <div className="payment-info-card-add-square-value">VALOR RESTANTE: {this.floatToCurrency(remainingValue)}</div>
                            </div>
                          </div>}
                        <div className='box-button-next-step-desktop'>
                        </div>
                      </div>}
                    {(currentStep === 3 || window.innerWidth > 991) &&
                      <div className={`payment-summary col`}>
                        <div>
                          <div className='payment-summary-title'>Resumo do pagamento</div>
                          <div className='payment-summary-payer'>
                            <span className='payment-summary-payer-title'>Pagador</span>
                            <div className='payment-summary-payer-data'>
                              <span className='span-text'>Nome</span>
                              <span className='span-info'>{paymentLinkData.payer.name}</span>
                            </div>
                            <div className='payment-summary-payer-data'>
                              <span className='span-text'>CPF</span>
                              <span className='span-info'>{ toCpfOrCnpj(paymentLinkData.payer.document) }</span>
                            </div>
                          </div>
                          <div className='payment-summary-payer'>
                            <span className='payment-summary-payer-title'>Beneficiário</span>
                            <div className='payment-summary-payer-data'>
                              <span className='span-text'>Nome</span>
                              {bankSlips.length > 1 ? 
                                <>
                                <button id='button-show-recipents' type="button" className={`button-show-recipents`} data-toggle="modal" data-target="#ModalShowRecipents">
                                  <span className='span-info'>Vários Beneficiários</span>
                                </button>
                                </>
                                :
                                <>
                                  <span className='span-info'>{bankSlip?.recipientName ?? '-'}</span>
                                </>
                              }
                            </div>
                            <div className='payment-summary-payer-data'>
                              <span className='span-text'>CNPJ</span>
                              {bankSlips.length > 1 ? 
                                <>
                                <button id='button-show-recipents' type="button" className={`button-show-recipents`} data-toggle="modal" data-target="#ModalShowRecipents">
                                  <span className='span-info'>Vários Beneficiários</span>
                                </button>
                                </>
                                :
                                <>
                                  <span className='span-info'>{ toCpfOrCnpj(bankSlip.recipientDocument) }</span>
                                </>
                              }
                            </div>
                          </div>
                          <div className='payment-summary-payer'>
                            <span className='payment-summary-payer-title'>Resumo do pagamento</span>
                            <div className='payment-summary-payer-data'>
                              <span className='span-text'>Valor total</span>
                              <span className='span-info'>{this.floatToCurrency(selectedCondition.totalAmount / 100)}</span>
                            </div>
                            <div className='payment-summary-payer-data'>
                              <span className='span-text'>Parcelas</span>
                              <span className='span-info'>{selectedCondition.installments}X de {this.floatToCurrency(selectedCondition.installmentAmount / 100)}</span>
                            </div>
                            {!isMultiplecards &&
                              <div className='payment-summary-payer-data'>
                                <span className='span-text'>Cartão</span>
                                <span className='span-info'>{getImgLogoCard(cards[0].cardBrand)} {cards[0].cardBrand === 'default' ? '' : cards[0].cardBrand} **** {cards[0].validField.validNumber === 'is-valid' ? cards[0].finalNumber : ''}</span>
                              </div>}
                          </div>

                          {isMultiplecards &&
                            <div className='payment-summary-payer'>
                              <span className='payment-summary-payer-title'>Cartões utilizados</span>
                              {cards.map(item => {
                                if (item.isEnabled)
                                  return (
                                    <div key={item.cardId} className='payment-summary-payer-data'>
                                      <span className='span-text'>{getImgLogoCard(item.cardBrand)} {item.cardBrand === 'default' ? '' : item.cardBrand} **** {item.validField.validNumber === 'is-valid' ? item.finalNumber : ''}</span>
                                      <span className='span-info'>{this.floatToCurrency(item.value)}</span>
                                    </div>
                                  )
                              })}
                            </div>
                          }
                        </div>
                        <div className='box-button-next-step-desktop'>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value={checkTermsAccepted} onChange={this.handleCheckboxTermsChange} id="flexCheckDefault"/>
                            <label className="form-check-label form-check-terms-label" htmlFor="flexCheckDefault">
                              Ao aceitar os <a id='hyperlink-terms-desktop' className="text-dark term-link" data-toggle="modal" data-target="#acceptTermModal">termos e condições</a>, você confirma estar ciente da transação e que sua solicitação foi atendida conforme o acordo celebrado. 
                            </label>
                          </div>
                          {processingPayment && <button id='button-processing-Payment-desktop' className={`btn btn-step custom-btn-bg-color`} type="button" disabled>
                            <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span>&nbsp;
                            PROCESSANDO...
                          </button>}
                          {!processingPayment && <button id='button-make-payment-desktop' type="button" className={`btn btn-step`} disabled={!this.state.allFieldsValidated || !checkTermsAccepted} onClick={this.onClickDoPayment}>REALIZAR PAGAMENTO</button>}
                        </div>
                      </div>}
                  </div>}
                </div>
                <div>
                  {currentStep === 1 &&
                    <div className='box-button-step'>
                      <span className='disclaimer-info-payment'>Realizar o pagamento até as 18h do dia {bankSlip && format(new Date(bankSlip.expirationDate), 'dd/MM/yyyy')}</span>
                      <button id='button-step-1-mobile' type="button" className="btn btn-step" onClick={() => this.toStep(2)}>PROSSEGUIR PARA O PAGAMENTO {'>'} </button>
                    </div>
                  }
                  {currentStep === 2 &&
                    <div className='box-button-step-2'>
                      <button id='button-back-step-2-mobile' type="button" className="btn btn-step-back" onClick={() => this.toStep(1)}>VOLTAR</button>
                      <button id='button-step-2-mobile' type="button" className="btn btn-step" disabled={!this.state.allFieldsValidated} onClick={() => this.toStep(3)}>AVANÇAR</button>
                    </div>
                  }
                  {currentStep === 3 &&
                    <>
                      <div className='disclaimer-info-terms text-center'>Ao clicar em “Realizar Pagamento” você concorda com os <a id='hyperlink-terms-mobile' className="text-dark term-link" data-toggle="modal" data-target="#acceptTermModal">termos de uso</a> do pagamento</div>
                      <div className='box-button-step-2'>
                        <button id='button-back-step-3-mobile' type="button" disabled={processingPayment} className="btn btn-step-back" onClick={() => this.toStep(2)}>VOLTAR</button>
                        {processingPayment && 
                          <button id='button-processing-Payment-mobile' className={`btn btn-step custom-btn-bg-color`} type="button" disabled>
                            <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span>&nbsp;
                            PROCESSANDO...
                          </button>
                        }
                        {!processingPayment && 
                          <button id='button-make-payment-mobile' type="button" className={`btn btn-step`} disabled={!this.state.allFieldsValidated} onClick={this.onClickDoPayment}>
                            REALIZAR PAGAMENTO
                          </button>
                        }
                      </div>
                    </>
                  }
                  <Footer checkoutConfig={checkoutConfig} paymentResultType={this.state.paymentResultType ? this.state.paymentResultType : null} />
                </div>
              </div>
            </>}
          </>}

          {/* Modal termos de uso */}
          <div className="modal fade" id="acceptTermModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Termo de uso e aceite eletrônico para uso do Quita+</h4>
                </div>
                <div className="modal-body text-center">
                  <iframe title="Termo de aceite" src={term} width="100%" height="700" style={{ border: 'none' }}></iframe>
                </div>
                <div className="modal-footer">
                  <button id='button-close-modal-terms' className="btn btn-secondary" data-dismiss="modal">Fechar</button>
                </div>
              </div>
            </div>
          </div>

          {/* Modal Select installments */}
          <div className="modal fade" id="ModalSelectInstallments" tabIndex="-1" role="dialog" aria-labelledby="TituloModalCentralizado" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{ height: '100%' }}>
              <div className="modal-content">
                <div className="modal-header-installments">
                  <h4 className="modal-title-installments">Selecionar parcelas</h4>
                </div>
                <div className="modal-body modal-body-installments text-center">
                  {conditions && conditions.map(condition => {
                      return (
                        <Fragment key={condition.id}>
                          <div className="d-flex justify-content-between">
                            <label htmlFor={condition.id}>{condition.installments}x {this.floatToCurrency(condition.installmentAmount / 100)}</label>
                            <input type="checkbox" className="rounded-checkbox" id="checkbox" checked={selectedCondition.id === condition.id ? true : false} onChange={() => this.onChangeCondition(condition.id)} />
                          </div>
                        </Fragment>
                      )
                  })}
                </div>
                <div className="modal-button-footer">
                  <button id='button-close-modal-select-installments' className="btn btn-step" data-dismiss="modal">CONFIRMAR NÚMERO DE PARCELAS</button>
                </div>
              </div>
            </div>
          </div>

          {/* Modal recipent */}
          <div className="modal fade" id="ModalShowRecipents" tabIndex="-1" role="dialog" aria-labelledby="TituloModalCentralizado" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{ height: '100%' }}>
              <div className="modal-content">
                <div className="modal-header-installments text-center">
                  <h4 className="modal-title-installments">Beneficiários</h4>
                </div>
                  {Object.keys(bankSlips).map((key) => (
                    <>
                      <div className="modal-body modal-body-recipents">
                        <div className="modal-bank-name">{bankSlips[key]?.recipientName ?? '-'}</div>
                        <div className="modal-bank-document">{ toCpfOrCnpj(bankSlips[key].recipientDocument) }</div>
                      </div>
                    </>
                  ))}
                <div className="modal-button-footer">
                  <button id='button-close-modal-select-installments' className="btn btn-step" data-dismiss="modal">FECHAR</button>
                </div>
              </div>
            </div>
          </div>

          {/* Modal tempo expirado */}
          <div className="modal fade" id="timeExpiredModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{ height: '100%' }}>
              <div className="modal-content">
                <div className="modal-body text-center">
                  <span style={{ fontSize: '80px' }} className="text-warning">
                    <FontAwesomeIcon icon={faClock} />
                  </span>
                  <h3 className="font-weight-normal"> O tempo para realizar este pagamento expirou. Para tentar novamente, recarregue esta página!</h3>
                </div>
                <div className="modal-button-footer">
                  <button id='button-close-modal-expired' type="button" className="btn btn-step" onClick={() => window.location.reload()}><FontAwesomeIcon icon={faRedoAlt} />&nbsp;Recarregar</button>
                </div>
              </div>
            </div>
          </div>

          {/* Modal de erro */}
          <div className="modal fade" id="transactionErrorModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document" style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
              <div className="modal-content modal-content-erro">
                <div className="modal-body modal-body-erro text-center">
                  <span style={{ fontSize: '40px' }} className="text-danger">
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </span>
                  {erroCards.length > 0 ?
                    <>
                      {erroCards.map(i => {
                            return(
                              <>
                                <h3 className="modal-erro-tittle">Verifique os seguintes cartões</h3>
                                <div className="modal-erro-cards">
                                  <div className={`modal-erro-card ${i.lastCard || erroCards.length === 1? '' : 'line-card-erro'}`}>
                                    <div className="modal-erro-card-info">
                                      {getImgLogoCard(i.cardBrand)}
                                      <div className="erro-card-brand">{returnNameCard(i.cardBrand)}</div>
                                      <div className="erro-final-card-number">**** {i.finalNumber}</div>
                                    </div>
                                    <div className="modal-erro-card-message">
                                      <div className="erro-card-message">{i.erroMessage}</div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                        })}
                    </>
                    :
                    <>
                      <h3 className="modal-erro-tittle">Ocorreu um erro</h3>
                      <div className="modal-erro-cards">{paymentErrorMessage}</div>
                    </>
                  }
                </div>
                <div className="modal-button-footer">
                  <button id='button-close-modal-error' type="button" className="btn btn-step" style={{ background: '#FD4646' }} data-dismiss="modal">CORRIGIR</button>
                </div>
              </div>
            </div>
          </div>

          {/* Modal de 3DS */}
          <div className="modal fade" id="tdsModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Procedimento de segurança</h4>
                </div>
                <div className="modal-body">
                  <p>Para finalizarmos o seu pagamento, realize o procedimento abaixo:</p>
                  <iframe title="Validação de Segurança" id="authenticationPageIframe" srcDoc={iframeSrcDoc} className="authenticationPageIframe" frameBorder="0" allowFullScreen scrolling="no"></iframe>
                </div>
              </div>
            </div>
          </div>

          {(loadingPage && !checkoutConfig) && <div className="text-center" style={{ position: 'relative', top: '50%' }}>
            <div className="spinner-border text-secondary" style={{ width: '4rem', height: '4rem' }} role="status">
              <span className="sr-only">Carregando...</span>
            </div>
          </div>}
        </div>
      </>
    )
  }
}